import gohighlevel from "./gohighlevel";
import universal from "./universal";
import simvoly from "./simvoly";
import calendly from "./calendly";
import brilliantdirectories from "./brilliantdirectories";
import boomerangme from "./boomerangme";
import bui from "./bui";
import wix from "./wix";
import router from "../../router";

export default function () {
    return router({universal,gohighlevel,simvoly,brilliantdirectories,boomerangme,wix,bui,calendly});
}
