import empty from "../../../empty";
import partnero from "../../../partnero";

export default function type() {
    return function _type(type) {
        this.$parent.$parent.type = type;
        partnero.setConfig('type', type, this.$parent.$parent.id);
        return empty;
    };
}
