import empty from "../../../../empty";
import partnero from "../../../../partnero";

export default function url_format() {
    return function _url_format(url_format) {
        this.$parent.$parent.$parent.url_format = url_format;
        partnero.setConfig('url_format', url_format, this.$parent.$parent.$parent.id);
        return empty;
    };
}
