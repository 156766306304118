import ctx from "./ctx";
import visitor from "./visitor";
import settings from "./settings";
import load from "./load";
import router from "../../../router.js";
import type from "./type";
import portal_widget from "./portal_widget";

export default function _id(id) {
    this.id ??= id;
    this.type ??= null;
    this.cookie_time ??= 30 * 24 * 60 * 60;
    this.url_format ??= 'query'
    this.query_param ??= 'ref'
    this.link_param ??= 'link'
    this.simple_tracking_enabled ??= false
    this.host ??= null;
    this.portal_widget_config ??= {};

    return router({ ctx, settings, visitor, load, type, portal_widget });
}
