import rootRouter from './routes/index'
import callable from './callable'

const po = callable(null, rootRouter)('https://partnero.com')

const partnerObject = window.__partnerObject || 'po';
const fakePo = window[partnerObject] || {q: []};

function proceedQueue(queue, handler) {

    queue.forEach(function (qItem) {
        const newHandler = handler.apply(null, qItem.a);

        if (qItem.q) {
            proceedQueue(qItem.q, newHandler);
        }
    });

    queue.push = function () {
        let args = Array.prototype.slice.call(arguments[0].a || []);
        return handler.apply(null, args);
    };
}

const queue = fakePo.q || [];
proceedQueue(queue, po);

window[partnerObject] = po;
