import empty from "../../../../empty";
import partnero from "../../../../partnero";

export default function portal_widget_config() {
    return function _portal_widget_config(portalWidgetConfig) {
        this.$parent.$parent.$parent.portal_widget_config = portalWidgetConfig;
        partnero.setConfig('portal_widget_config', portalWidgetConfig, this.$parent.$parent.$parent.id);
        return empty;
    };
}
