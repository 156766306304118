import empty from "../../../../empty";
import partnero from "../../../../partnero";

export default function simple_tracking_enabled() {
    return function _simple_tracking_enabled(simpleTrackingEnabled = false) {
        simpleTrackingEnabled = Boolean(simpleTrackingEnabled);
        this.$parent.$parent.$parent.simple_tracking_enabled = simpleTrackingEnabled;
        partnero.setConfig('simple_tracking_enabled', simpleTrackingEnabled, this.$parent.$parent.$parent.id);
        return empty;
    };
}
