import empty from "../../../../empty";
import partnero from "../../../../partnero";

export default function lnk_param() {
    return function _link_param(linkParam = 'link') {
        this.$parent.$parent.$parent.link_param = linkParam;
        partnero.setConfig('link_param', linkParam, this.$parent.$parent.$parent.id);
        return empty;
    };
}
