export default function portal_widget() {
    const portalWidgetConfig = this.$parent.portal_widget_config;

    portalWidgetConfig.host = this.$parent.$parent.$parent.host;

    if (parseInt(portalWidgetConfig.enabled) === 1) {
        appendCss(portalWidgetConfig)
        appendHtml(portalWidgetConfig)

        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', () => initialize(portalWidgetConfig));
        } else {
            initialize(portalWidgetConfig);
        }
    }
}

function appendCss(portalWidgetConfig)
{
    const style = document.createElement('style');
    style.textContent = `
        #po-widget-wrapper {
            position: fixed;
            bottom: 0;
            right: 0;
            z-index: ${portalWidgetConfig.z_index};
        }
        #po-widget-wrapper #po-widget-launcher {
            position: fixed;
            bottom: 20px;
            ${portalWidgetConfig.horizontal_position === 'left' ? 'left' : 'right'}: 20px;
            width: auto;
            height: 60px;
            border-radius: 30px;
            overflow: hidden;
            z-index: ${portalWidgetConfig.z_index};
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
        }
        #po-widget-wrapper #po-widget-launcher-button {
            display: flex;
            align-items: center;
            padding: 0 18px;
            width: 100%;
            height: 100%;
            border: none;
            background-color: ${portalWidgetConfig.color};
            cursor: pointer;
            border-radius: 30px;
            position: relative;
        }
        #po-widget-wrapper #po-widget-launcher-button #po-widget-portal-open-icon,
        #po-widget-wrapper #po-widget-launcher-button #po-widget-portal-close-icon {
            align-items: center;
            width: 24px;
            height: 24px;
        }
        #po-widget-wrapper #po-widget-launcher-button svg {
            margin: auto;
            fill: ${portalWidgetConfig.icon_color};
        }
        #po-widget-wrapper #po-widget-launcher-button #po-widget-launcher-button-title {
            margin-left: 5px;
            font-size: 16px;
            color: ${portalWidgetConfig.icon_color};
            display: block;
            font-family: Helvetica, Arial, sans-serif;
        }
        #po-widget-wrapper #po-widget-launcher-button #po-widget-portal-open-icon {
            display: block;
        }
        #po-widget-wrapper #po-widget-launcher-button #po-widget-portal-close-icon {
            display: none;
        }
        #po-widget-wrapper .po-widget-opened #po-widget-launcher-button #po-widget-launcher-button-title,
        #po-widget-wrapper .po-widget-opened #po-widget-launcher-button #po-widget-portal-open-icon {
            display: none !important;
        }
        #po-widget-wrapper .po-widget-opened #po-widget-launcher-button #po-widget-portal-close-icon {
            display: block !important;
        }
        #po-widget-wrapper #po-widget-portal {
            position: fixed;
            bottom: 90px;
            ${portalWidgetConfig.horizontal_position === 'left' ? 'left' : 'right'}: 20px;
            width: 300px;
            height: 400px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            display: none;
            border-radius: ${portalWidgetConfig.corner_radius};
        }
        #po-widget-wrapper #po-widget-portal iframe#po-widget-portal-iframe {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: ${portalWidgetConfig.corner_radius};
        }
        #po-widget-wrapper #po-widget-portal.po-widget-opened {
            display: block !important;
        }
    `;
    document.head.appendChild(style);
}

function appendHtml(portalWidgetConfig)
{
    const portalWidgetWrapper = document.createElement('div');
    portalWidgetWrapper.id = 'po-widget-wrapper';
    portalWidgetWrapper.innerHTML = `
        <div id="po-widget-launcher" style="display: none;">
            <button id="po-widget-launcher-button">
                <span id="po-widget-portal-open-icon"></span>
                <span id="po-widget-portal-close-icon"></span>
                ${portalWidgetConfig.launcher_title ? `<span id="po-widget-launcher-button-title">${portalWidgetConfig.launcher_title}</span>` : ''}
            </button>
        </div>
        <div id="po-widget-portal">
            <iframe id="po-widget-portal-iframe" title="Partnero widget"></iframe>
        </div>
    `;
    document.body.appendChild(portalWidgetWrapper);
}

function initialize(portalWidgetConfig)
{
    const portalContainer = document.getElementById('po-widget-portal');
    const launcherContainer = document.getElementById('po-widget-launcher');
    const launcherButton = document.getElementById('po-widget-launcher-button');
    const portalIframe = document.getElementById('po-widget-portal-iframe');

    portalIframe.src = portalWidgetConfig.portal_url;

    const iconLoaders = [];
    if (portalWidgetConfig.icons.open) {
        iconLoaders.push(loadIcon(portalWidgetConfig.icons.open, 'po-widget-portal-open-icon'))
    }
    if (portalWidgetConfig.icons.close) {
        iconLoaders.push(loadIcon(portalWidgetConfig.icons.close, 'po-widget-portal-close-icon'))
    }

    Promise.all(iconLoaders).then(() => {
        launcherContainer.style.display = 'block'
        launcherButton.addEventListener('click', () => {
            portalContainer.classList.toggle('po-widget-opened');
            launcherContainer.classList.toggle('po-widget-opened');
        });
    })
}

function loadIcon(url, elementId)
{
    return fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.text(); // Assuming the response is text content
        })
        .then(data => {
            document.getElementById(elementId).innerHTML = data
        })
}
